<template>
    <div id="soumission" class="page-wrapper page-soumission">
        <div class="site-max-width off-mobile">
            <section id="form-container" class="form-container">
                <h3 class="regular-title" data-inview="fadeInUp" data-delay="100" v-if="pageIsLoaded">{{ content.introBlock1.surtitre }}</h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300" v-if="pageIsLoaded"><span v-html="content.introBlock1.titre"></span></p>

                <form
                    class="full-form"
                    name="postuler"
                    action=""
                    method="post"
                    accept-charset="UTF-8"
                    @submit="sendForm($event)"
                    enctype="multipart/form-data"
                    data-inview="fadeInUp" data-delay="400"
                >
                    <input type="hidden" name="action" value="contact-form/send" />

                    <div class="input-row half">
                        <label for="nom">{{ $t('Nom') }}</label>
                        <input type="text" id="nom" name="nom" v-model="formData.nom" :placeholder="$t('Nom')" required />
                    </div>

                    <div class="input-row half">
                        <label for="prenom">{{ $t('Prénom') }}</label>
                        <input type="text" id="prenom" name="prenom" v-model="formData.prenom" :placeholder="$t('Prénom')" required />
                    </div>

                    <div class="input-row half">
                        <label for="telephone">{{ $t('Téléphone') }}</label>
                        <input type="text" id="telephone" name="telephone" v-model="formData.telephone" :placeholder="$t('Téléphone')" />
                    </div>

                    <div class="input-row half">
                        <label for="email">{{ $t('Courriel') }}</label>
                        <input type="email" id="email" name="email" v-model="formData.courriel" :placeholder="$t('Courriel')" required />
                    </div>

                    <div class="grid-choices">
                        <h4 class="medium-title title">{{ $t('Choisissez le type de produit') }}</h4>
                        <div class="items" :class="{ 'has-active': formData.type }">
                            <div class="item" :class="{ 'is-active': formData.type === 'Étiquettes' }" @click.prevent="selectType('Étiquettes')">
                                <img src="@/assets/img/choice-1.jpg" alt="Étiquettes">
                                <h2 class="small-title item-title">{{ $t('Étiquettes') }}</h2>
                            </div>
                            <div class="item" :class="{ 'is-active': formData.type === 'Sleeves' }" @click.prevent="selectType('Sleeves')">
                                <img src="@/assets/img/soumission-sleeves.jpg" alt="Sleeves">
                                <h2 class="small-title item-title">{{ $t('Sleeves') }}</h2>
                            </div>
                            <div class="item" :class="{ 'is-active': formData.type === 'Sachet' }" @click.prevent="selectType('Sachet')">
                                <img src="@/assets/img/soumission-sachet.jpg" alt="Sachet">
                                <h2 class="small-title item-title">{{ $t('Sachet') }}</h2>
                            </div>
                        </div>
                    </div>

                    <transition name="fade" mode="out-in">
                        <div class="wrap-type" v-if="formData.type === 'Étiquettes'">
                            <div class="input-row half">
                                <label for="type-materiel">{{ $t('Quel est le type de matériel ?') }}</label>
                                <select name="type-materiel" id="type-materiel" v-model="formData.typeMateriel">
                                    <option value="">{{ $t('Quel est le type de matériel ?') }}</option>
                                    <option :value="item" v-for="item in typesDeMateriels" :key="item">{{ item }}</option>
                                </select>
                            </div>

                            <div class="input-row half">
                                <label for="type-adhesif">{{ $t("Quel type d'adhésif ?") }}</label>
                                <select name="type-adhesif" id="type-adhesif" v-model="formData.typeAdhesif">
                                    <option value="">{{ $t("Quel type d'adhésif ?") }}</option>
                                    <option :value="item" v-for="item in typesAdhesifs" :key="item">{{ item }}</option>
                                </select>
                            </div>

                            <span class="input-title">{{ $t('Quelle est la forme et dimension ?') }}</span>
                            <div class="input-multiples">
                                <div class="radio-box">
                                    <label for="carre">{{ $t('Carré') }}</label>
                                    <input type="radio" name="dimension" id="carre" v-model="formData.dimension" value="Carré">
                                </div>
                                <div class="radio-box">
                                    <label for="rectangle">{{ $t('Rectangle') }}</label>
                                    <input type="radio" name="dimension" id="rectangle" v-model="formData.dimension" value="Rectangle">
                                </div>
                                <div class="radio-box">
                                    <label for="Cercle">{{ $t('Cercle') }}</label>
                                    <input type="radio" name="dimension" id="Cercle" v-model="formData.dimension" value="Cercle">
                                </div>
                                <div class="radio-box">
                                    <label for="ovale">{{ $t('Ovale') }}</label>
                                    <input type="radio" name="dimension" id="ovale" v-model="formData.dimension" value="Ovale">
                                </div>
                                <div class="radio-box long">
                                    <label for="irregulier">{{ $t('Irrégulier') }}</label>
                                    <input type="radio" name="dimension" id="irregulier" v-model="formData.dimension" value="Irrégulier">
                                    <input class="inner-input" type="text" name="hauter" id="hauter" v-model="formData.dimensionHauteur" :placeholder="$t('Hauteur')">
                                    <input class="inner-input" type="text" name="hauter" id="hauter" v-model="formData.dimensionLargeur" :placeholder="$t('Largeur')">
                                    <span class="inner-text">CM</span>
                                </div>
                            </div>

                            <div class="input-row half">
                                <label for="quantite">{{ $t('Quelle est la quantité demandée ?') }}</label>
                                <input type="number" min="1" max="100000000" step="1" id="quantite" name="quantite" v-model="formData.quantite" :placeholder="$t('Quelle est la quantité demandée ?')" />
                            </div>

                            <div class="input-row half">
                                <label for="nombre-sku2">{{ $t('Nombre de versions (sku) ?') }}</label>
                                <input type="number" min="1" max="99" step="1" id="nombre-sku2" name="nombre-sku" v-model="formData.nombreSku" :placeholder="$t('Nombre de versions (sku) ?')" />
                            </div>

                            <div class="input-row half">
                                <label for="nombre-couleurs3">{{ $t('Nombre de couleurs ?') }}</label>
                                <input type="number" min="1" max="10" step="1" id="nombre-couleurs3" name="nombre-couleurs" v-model="formData.nombreCouleurs" :placeholder="$t('Nombre de couleurs ?')" />
                            </div>

                            <div class="input-row half">
                                <label for="fichier">{{ $t('Ajouter votre fichier') }}</label>
                                <div class="file-input-wrap">
                                    <span>{{ $t('Ajouter votre fichier') }}</span>
                                    <input type="file" id="fichier" name="file" accept=".pdf" @change="uploadFile($event)" :placeholder="$t('Ajouter votre fichier')" />
                                    <p class="helper">
                                        {{ $t('Svp envoyer les visuels en PDF.') }} <br/> {{ $t('Avec espace où insérer un document de visuel') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <transition name="fade" mode="out-in">
                        <div class="wrap-type" v-if="formData.type === 'Sleeves'">
                            <div class="input-row half">
                                <label for="hauter">{{ $t('Quelles sont les dimensions ?') }}</label>
                                <div class="dimenssion-box">
                                    <label for="irregulier">{{ $t('Quelles sont les dimensions ?') }}</label>
                                    <input class="inner-input" type="text" name="hauter" id="hauter" v-model="formData.dimensionHauteur" :placeholder="$t('Hauteur')">
                                    <input class="inner-input" type="text" name="hauter" id="hauter" v-model="formData.dimensionLargeur" :placeholder="$t('Largeur')">
                                    <span class="inner-text">CM</span>
                                </div>
                            </div>

                            <div class="input-row half">
                                <label for="quantite2">{{ $t('Quelle est la quantité demandée ?') }}</label>
                                <input type="number" min="1" max="100000000" step="1" id="quantite2" name="quantite" v-model="formData.quantite" :placeholder="$t('Quelle est la quantité demandée ?')" />
                            </div>

                            <div class="input-row half">
                                <label for="nombre-sku3">{{ $t('Nombre de versions (sku) ?') }}</label>
                                <input type="number" min="1" max="99" step="1" id="nombre-sku3" name="nombre-sku" v-model="formData.nombreSku" :placeholder="$t('Nombre de versions (sku) ?')" />
                            </div>

                            <div class="input-row half">
                                <label for="nombre-couleurs">{{ $t('Nombre de couleurs ?') }}</label>
                                <input type="number" min="1" max="10" step="1" id="nombre-couleurs" name="nombre-couleurs" v-model="formData.nombreCouleurs" :placeholder="$t('Nombre de couleurs ?')" />
                            </div>

                            <div class="input-row half">
                                <label for="type-finition">{{ $t('Type de Finition ?') }}</label>
                                <select name="type-finition" id="type-finition" v-model="formData.typeFinition" place>
                                    <option value="">{{ $t('Type de Finition ?') }}</option>
                                    <option :value="item" v-for="item in typesFinitions" :key="item">{{ item }}</option>
                                </select>
                            </div>

                            <div class="input-row half">
                                <label for="fichier2">{{ $t('Ajouter votre fichier') }}</label>
                                <div class="file-input-wrap">
                                    <span>{{ $t('Ajouter votre fichier') }}</span>
                                    <input type="file" id="fichier2" name="file" accept=".pdf" @change="uploadFile($event)" :placeholder="$t('Ajouter votre fichier')" />
                                    <p class="helper">
                                        {{ $t('Svp envoyer les visuels en PDF.') }} <br/> {{ $t('Avec espace où insérer un document de visuel') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <transition name="fade" mode="out-in">
                        <div class="wrap-type" v-if="formData.type === 'Sachet'">
                            <div class="input-row half">
                                <label for="hauter">{{ $t('Quelles sont les dimensions ?') }}</label>
                                <div class="dimenssion-box">
                                    <label for="irregulier">{{ $t('Quelles sont les dimensions ?') }}</label>
                                    <input class="inner-input" type="text" name="hauter" id="hauter" v-model="formData.dimensionHauteur" :placeholder="$t('Hauteur')">
                                    <input class="inner-input" type="text" name="hauter" id="hauter" v-model="formData.dimensionLargeur" :placeholder="$t('Largeur')">
                                    <span class="inner-text">CM</span>
                                </div>
                            </div>

                            <div class="input-row half">
                                <label for="type-materiel">{{ $t('Quel est le type de matériel ?') }}</label>
                                <select name="type-materiel" id="type-materiel" v-model="formData.typeMateriel">
                                    <option value="">{{ $t('Quel est le type de matériel ?') }}</option>
                                    <option :value="item" v-for="item in typesDeMaterielsSachets" :key="item">{{ item }}</option>
                                </select>
                            </div>

                            <div class="input-row half">
                                <label for="quantite3">{{ $t('Quelle est la quantité demandée ?') }}</label>
                                <input type="number" min="1" max="100000000" step="1" id="quantite3" name="quantite" v-model="formData.quantite" :placeholder="$t('Quelle est la quantité demandée ?')" />
                            </div>

                            <div class="input-row half">
                                <label for="nombre-sku">{{ $t('Nombre de versions (sku) ?') }}</label>
                                <input type="number" min="1" max="99" step="1" id="nombre-sku" name="nombre-sku" v-model="formData.nombreSku" :placeholder="$t('Nombre de versions (sku) ?')" />
                            </div>

                            <div class="input-row half">
                                <label for="nombre-couleurs2">{{ $t('Nombre de couleurs ?') }}</label>
                                <input type="number" min="1" max="10" step="1" id="nombre-couleurs2" name="nombre-couleurs" v-model="formData.nombreCouleurs" :placeholder="$t('Nombre de couleurs ?')" />
                            </div>

                            <div class="input-row half">
                                <label for="fichier3">{{ $t('Ajouter votre fichier') }}</label>
                                <div class="file-input-wrap">
                                    <span>{{ $t('Ajouter votre fichier') }}</span>
                                    <input type="file" id="fichier3" name="file" accept=".pdf" @change="uploadFile($event)" :placeholder="$t('Ajouter votre fichier')" />
                                    <p class="helper">
                                        {{ $t('Svp envoyer les visuels en PDF.') }} <br/> {{ $t('Avec espace où insérer un document de visuel') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <div class="input-wrapper" v-if="formData.type">
                        <input class="site-btn alternate" type="submit" :value="$t('Envoyer')" v-if="!isSending" />
                    </div>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'

import axios from 'axios'
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'

import { mapGetters, mapState } from 'vuex'

export default {
    name: 'Soumission',

    data() {
        return {
            formMessage: '',
            formMessageStatus: '',
            isSending: false,
            formData: {
                type: '',
                typeAdhesif: '',
                typeMateriel: '',
                typeFinition: '',
            },
            typesDeMateriels: ['Papier blanc', 'Film transparent', 'Film blanc', 'Transfer thermal', 'direct thermal'],
            typesDeMaterielsSachets: ['PPFP', 'Cosmétique Web', 'Autres'],
            typesAdhesifs: ['Permanent', 'Repositionnable', 'Amovible', 'Congélateur'],
            typesFinitions: ['Rouleau', 'Feuille'],
            formes: ['Carré', 'rectangle', 'cercle', 'ovale', 'irrégulier']
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    initInViewAnimations()
                }
            },
            immediate: true,
        },
    },

    mounted() {

    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        selectType(type) {
            this.formData.type = type
        },
        async uploadFile(event) {
            const fileName = event.target.files[0].name
            this.formData.file = fileName
            this.formData = { ...this.formData }

            // Handling file
            this.formData.attachment = event.target.files[0]
        },
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            // this.formData[this.content.content.form.csrfName] = this.content.content.form.csrf
            this.formData['action'] = 'contact-form/send'
            this.formData['formName'] = 'Soumission'
            this.formData['sujet'] = 'Soumission'

            const formData = new FormData()
            formData.append('attachment', this.formData.attachment)
            formData.append('formName', this.formData.formName)
            formData.append('sujet', this.formData.sujet)

            formData.append('nom', this.formData.nom)
            formData.append('prenom', this.formData.prenom)
            formData.append('telephone', this.formData.telephone)
            formData.append('courriel', this.formData.courriel)

            formData.append('type', this.formData.type)

            formData.append('typeMateriel', this.formData.typeMateriel)
            formData.append('typeAdhesif', this.formData.typeAdhesif)
            formData.append('typeFinition', this.formData.typeFinition)
            formData.append('dimension', this.formData.dimension)
            formData.append('dimensionHauteur', this.formData.dimensionHauteur)
            formData.append('dimensionLargeur', this.formData.dimensionLargeur)
            formData.append('quantite', this.formData.quantite)
            formData.append('nombreSku', this.formData.nombreSku)
            formData.append('nombreCouleurs', this.formData.nombreCouleurs)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

             await axios
                .post(`${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`, formData, config)
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: 'Votre demande de soumission a été envoyé avec succès.',
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    setTimeout(() => {
                        this.formData = {
                            type: '',
                            typeAdhesif: '',
                            typeMateriel: '',
                            typeFinition: '',
                        }

                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
                .catch((e) => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
    },
}
</script>
